import { View, Text, StyleSheet, Pressable, useWindowDimensions } from "react-native"
import Modules from "./Modules";
import Top from "./Top";
import React, {Suspense, useState } from "react";
import styles from "../../styles";
import Opciones from "./Opciones";
import Global from "../../Global";

export default function Panel ({usuario, setUsuario}) {
    const [perfil, setPerfil] = useState(null);
    const [modulo, setModulo] = useState(null);
    const [ventanas, setVentanas] = useState([]);
    const [ventana, setVentana] = useState(null);
    const {height, width} = useWindowDimensions();
    if (perfil == null) setPerfil(usuario.usuario.perfiles[0]);
    if (perfil != null && perfil.modulos.length > 0 && modulo == null) setModulo(perfil.modulos[0]);
    return (
        <View style={styles.main}>
            <Modules perfil={perfil} modulo={modulo} setModulo={setModulo} setVentana={setVentana}/>
            <View style={{width: width - Global.MODULEAREAWITH}} >
                <Top width={width} height={height} perfil={perfil} setPerfil={setPerfil} usuario={usuario} setUsuario={setUsuario} ventanas={ventanas} ventana={ventana} setVentana={setVentana} setVentanas={setVentanas}></Top>
                    <Opciones visible={ventana == null} modulo={modulo} ventanas={ventanas} setVentanas={setVentanas} setVentana={setVentana}></Opciones>
            {
                ventanas.map((item, index) => (
                    <Suspense key={`s${item.idopcion}${index}`} fallback={<Text>Procesando ....</Text>}>
                        <item.component usuario={usuario} ventana={item} visible={ventana == item}/>
                    </Suspense>
                ))
            }   
            </View>
        </View>
    );
}
