import { StatusBar } from 'expo-status-bar';
import { SafeAreaView, View, Text, Platform} from 'react-native';
import { ActivityIndicator, configureFonts, MD3LightTheme, PaperProvider } from 'react-native-paper';
import styles from './styles';
import * as Font from 'expo-font';
import Panel from './src/components/Panel';
import React, { useState, useEffect , Fragment} from 'react';
import { getSession } from './src/libraries/StorageService';
import Login from './src/components/Login';

const fontConfig = {
  "default": {
    fontFamily: 'Poppins', 
  },
  "bodySmall": {
    fontFamily: 'Poppins', 
  },
  "displaySmall": {
    fontFamily: "Poppins-Bold"
  },
  "displayMedium": {
    fontFamily: "Poppins-Bold"
  },
  "textinput": {
    fontFamily: "Poppins"
  },
  "displayLarge": {
    fontFamily: "Poppins-Bold"
  }, 
  "titleMedium": {
    fontFamily: "Poppins-Bold"
  }, 
  "labelSmall": {
    fontFamily: "Poppins"
  }
};
let customFonts = {
  'Poppins': require('./assets/fonts/Poppins-Regular.ttf'),
  'Poppins-Black': require('./assets/fonts/Poppins-Black.ttf'),
  'Poppins-Bold': require('./assets/fonts/Poppins-Bold.ttf')
};

const theme = {
  ...MD3LightTheme,
  fonts: configureFonts({config: fontConfig}),
};

export default function App() {
  const [fontLoaded, setFontLoaded] = useState(false);
  useEffect(() => {
    loadFonts();
  }, []);
  const loadFonts = async () => {
    await Font.loadAsync(customFonts);
    setUsuario(getSession());
    setFontLoaded(true);
  };
  const [usuario, setUsuario] = useState(null);

  return (
    <PaperProvider theme={theme}>
      <Fragment>
        {Platform.OS === 'web' ? (
          <style type="text/css">{`
            @font-face {
              font-family: 'MaterialCommunityIcons';
              src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
            }

            @font-face {
              font-family: 'FontAwesome';
              src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
            }; 
          `}</style>
        ) : null}
    </Fragment>
      {
      !fontLoaded && (
          <SafeAreaView style={[styles.container, {justifyContent: 'center',  alignItems: 'center'}]}>
            <ActivityIndicator size="large" color='red'></ActivityIndicator>
          </SafeAreaView>
        )
      }
      {
        usuario !== null ?   (
          <Panel usuario={usuario} setUsuario={setUsuario}></Panel>
        ) : (
          <Login setUsuario={setUsuario}/>
        )
      }
    </PaperProvider>

  );
}