'use strict';
import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    h1: {
        fontFamily: 'Poppins-Bold',
        fontSize: 30, 
    },
    h2: {
        fontFamily: 'Poppins-Bold',
        fontSize: 26, 
    },
    h3: {
        fontFamily: 'Poppins-Bold',
        fontSize: 22, 
    },
    h4: {
        fontFamily: 'Poppins-Bold',
        fontSize: 18, 
    },
    h5: {
        fontFamily: 'Poppins-Bold',
        fontSize: 14, 
    },
    modPanel: {
        width: 50,
    },
    reportContainer: {
        flexGrow: 1,
        flexDirection: "row", 
    },
    container: {
        flex: 1, 
        width: "100%",
        backgroundColor: "#F2F2F7"
    },
    main: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: 30,
        backgroundColor: "#fff",
        paddingTop: Platform.OS === "android" && 30,
    },
    titulo: {
        fontFamily: 'Poppins-Bold',
        fontSize: 40,
    },
    primary: {
        color: '#7459D9'
    },
    secondary: {
        
    },  
    indigo: {
        color: '#7459D9',
    }, 
    menuActive: {
        fontSize: 12,
        fontFamily: "Poppins-Bold",
        backgroundColor: "#efefef",
        paddingHorizontal: 8,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginHorizontal: 0,
        paddingTop: 12,
        paddingBottom: 4,
        width: 'auto',
        textAlign: "center", 
        flexDirection: "row"
    },
    menuNormal: {
        fontSize: 12,
        fontFamily: "Poppins", 
        color: "#D0D1D2",
        backgroundColor: "#d8d8d8",
        alignContent: "center",
        borderRightColor: '#888888',
        borderRightWidth: 1,
        paddingHorizontal: 8,
        borderTopLeftRadius: 8,
        marginTop: 12,
        paddingBottom: 4,
        textAlign: "center",
        flexDirection: "row"
    },
    borderBottom: {
        borderBottomWidth: 2, 
        borderBottomColor: '#f9f9f9'
    }, 
    imageIcon: {
        width: 40,
        height: 40, 
        borderRadius: 40
    },
    textCenter: {
        textAlign: 'center'
    }
});


