import { View, ActivityIndicator, StyleSheet, Platform } from "react-native"
import styles from "../../styles";
import { TextInput, Avatar, Text, Button, HelperText } from 'react-native-paper';
import { useState } from "react";
import { ImageBackground } from "react-native-web";
import { postDataAsync } from "../libraries/ApiService.js";
import { saveSession } from "../libraries/StorageService.js";

export default function Login ({setUsuario}) {
    const [userName, setUserName] = useState("");
    const [clave, setClave] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading]  = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const hasUserError = () => {
        return userName.trim() !== "" && userName.length < 3;
      };
    const login = async() => {
        try {
            setLoading(true);
            const user = await postDataAsync('auth/login', 
            {
                usuario: userName, 
                clave: clave
            });
            if (user) {
                saveSession(user);
                setUsuario(user);
            }
        } catch(e) {
            setError(e.message);
        }
        setLoading(false);
    };
    return (
        <View style={style.area}>
            <View style={style.loginArea}>
                <Avatar.Image style={{marginBottom: 24}} size={80} source={require('../../assets/gsi.png')} />
                <form onSubmit={() => login()} action="#">
                <View style={{
                    width: Platform.select(
                    {
                        web: 280,
                        default: '100%'
                    }), marginBottom: 16, paddingHorizontal: 16}}>
                    <Text variant="titleMedium">Inicie Sesión</Text>
                    <Text variant="labelSmall">Ingrese su usuario y contraseña para acceder al sistema</Text>
                    <TextInput
                        label="Usuario"
                        mode="outlined"
                        value={userName}
                        onChangeText={text => setUserName(text)}
                        right={<TextInput.Affix text="" />}
                    />
                    <TextInput
                        label="Contraseña"
                        mode="outlined"
                        secureTextEntry={!showPassword}
                        value={clave}
                        onChangeText={text => setClave(text)}
                        right={<TextInput.Icon onPress={() => {setShowPassword(!showPassword)}} icon="eye" />}
                    />
                    <Button type="submit" icon="key" mode="outlined" style={{marginTop: 30, width: '100%', borderRadius: 8}} onPress={() => login()}>
                        {!loading ? (<Text>Iniciar Sesión</Text>): (<ActivityIndicator size="small" color="#0000ff" />)}
                    </Button>
                    { hasUserError() && <HelperText type="error" padding="none"> 
                        Ingrese usuario o número de identificación válido
                    </HelperText>}
                    { (error != "") && <HelperText type="error" >{error}</HelperText>}
                </View>
                </form>
            </View>
            <View style={style.imageArea}>
                <ImageBackground style={style.background} source={require('../../assets/sifondo.png')}/>
            </View>
        </View>
    );
}

let style = StyleSheet.create({
    area: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loginArea: {
        flex: 1,
        width: '100%', 
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }, 
    imageArea: {
        flex: 1,
        display: screen.width > 800 ? "flex": "none",
        width: '100%', 
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        
    }, 
    background: {
        width: '100%', 
        height: '100%',
        resizeMode: 'cover'
    }
});