const sUserKey = "gsiuser";

export function saveSession(usuario) {
    sessionStorage.setItem(sUserKey, JSON.stringify(usuario));
}

export function getSession() {
    var sUser = sessionStorage.getItem(sUserKey);
    if (sUser) 
        return JSON.parse(sUser);
    else 
        return null;
}

export function clearSession() {
    sessionStorage.removeItem(sUserKey);
}