import { View, StyleSheet, useWindowDimensions } from "react-native"
import { FAB } from 'react-native-paper';
import React, { lazy } from "react";
import styles from "../../styles";

function loadVentana(opcion, ventanas, setVentanas, setVentana) {
    let item = ventanas.find(e => e.idopcion == opcion.idopcion);
    if (item) {

    } else {
        item = null;
        if (opcion != null && opcion.option != null)
            item = {
                ...opcion, 
                component: lazy(() => import(`../${opcion.option}`))
            }
        else 
            item = {
                ...opcion,
                component: lazy(() => import(`../reports/reportv1`))
            }
        ventanas.push(item);
        setVentanas(ventanas);
    }
    setVentana(item);
}

export default function ({modulo, ventanas, setVentanas, setVentana, visible}) {
    return <View style={{display: visible ? 'flex': "none" , padding:16,flexDirection: "row", flexWrap: "wrap"}}>
            { modulo && modulo.opciones.map((item, index) => (
            <FAB 
                size="large" 
                style={{margin: 8, borderRadius:8}} 
                onPress={() => {loadVentana(item, ventanas, setVentanas, setVentana)}} 
                label={item.nombreopcion}
                mode="elevated"
                variant="surface"
                icon={item.icono != null ? item.icono: "chart-box-outline"} 
                key={`o${index}`}/>
        ))}
            </View>
}

const style = StyleSheet.create({
    surface: {
        padding: 8,
        margin: 8,
        alignItems: "center",
        justifyContent: "center"
    }
});