import { View, Text, Image, StyleSheet, Pressable } from "react-native"
import { ActivityIndicator } from 'react-native-paper';
import styles from "../../styles";
import Global from "../../Global";

export default function Modules ({perfil, modulo, setModulo, setVentana}) {
    return (
        <View style={style.area}>
            <View style={style.logo}></View>
        { perfil && perfil.modulos.map((item, index) => {
            item.pathimage = require(`../../assets/${item.logomodulo}`);
            return <Pressable style={{filter: modulo == item ? "": "grayScale(1)", opacity: modulo == item ? 1: 0.5  }} onPress={() => { setModulo(item); setVentana(null)}} key={`m${index}`}>
                <Image style={[styles.imageIcon, style.imageIcon]} resizeMode="stretch" source={item.pathimage}/>
            </Pressable>
        })}
        </View>
    );
}

const style = StyleSheet.create({
    area: {
        display: 'inline-flex',
        padding: 0,
        margin: 0,
        width: Global.MODULEAREAWITH, 
        borderRightWidth: 1, 
        borderColor: '#aaa',
        flexDirection: "column",
        alignItems: 'center'
    }, 
    logo: {
        paddingVertical: 8,
        borderBottomWidth: 2,
        borderBottomColor: "#f9f9f9",
        height: 64, 
        width: 50
    },
    imageIcon: {
        width: 50, 
        height: 50,
        borderRadius: 25, 
        marginVertical: 8
    }
});