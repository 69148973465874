import { View, Text, Image, Pressable, Platform, StyleSheet} from "react-native"
import Icon from 'react-native-vector-icons/FontAwesome';
import { Menu, Divider } from 'react-native-paper';
import styles from "../../styles";
import { useState } from "react";
import { clearSession } from "../libraries/StorageService";
import Global from "../../Global";

export default function Top ({usuario, setUsuario, ventanas, ventana, setVentanas, setVentana, height, width, perfil, setPerfil}) {
    const [visible, setVisible] = useState(false);
    const openMenu = () => setVisible(true);
    const closeMenu = () => setVisible(false);
    return (
        <View style={
            {
                display: '-webkit-flex',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#d8d8d8',
                WebkitFlexDirection: 'row',
                overflow: "visible",
                paddingTop: 10,
            }}>
            <View style={
                {
                    display: Platform.OS === "web" ? "": "none",
                    maxWidth: width - (Global.MODULEAREAWITH + 80),
                    overflowX: "auto", 
                    paddingHorizontal: 8,
                    webKitFlex: "auto",
                    flex: "auto",
                }}>
                <View style={
                {
                    display: "flex",
                    flexDirection: "row", 
                }}>
                    <Pressable onPress={() => {setVentana(null);}} style={ {...(ventana == null ? styles.menuActive: styles.menuNormal), width: 42}}>
                        <Icon name="home" size={24}/>
                    </Pressable>
                {
                    ventanas.map((item, index) => (
                        <Pressable style={ventana == item ? styles.menuActive: styles.menuNormal} onPress={() => {setVentana(item);}} key={`v${index}`}>
                            <Text>{item.nombreopcion}</Text>
                            <Pressable onPress={() => {setVentana(null);setVentanas(ventanas.filter((e) => e != item));}} style={{paddingLeft: 8, paddingTop: 4}}><Icon name="close" color={"#444444"} size={12}/></Pressable>
                        </Pressable>
                    ))
                }
                </View>
            </View>
            <Menu 
                visible={visible}
                onDismiss={closeMenu}
                anchorPosition="bottom"
                theme={{ colors: { primary: 'green' } }}
                anchor={<Pressable onPress={openMenu} style={{
                        width: 200, 
                        paddingHorizontal: 8,
                        flex: 1,
                        flexDirection: "row"
                    }}>
                    {
                    <Image style={styles.imageIcon} resizeMode="stretch" source={require('../../assets/nophoto.png')}/>
                    }
                    <Text>{usuario.usuario.info.razonsocial}</Text>
                    <Icon name="chevron-down" size={15} color="#9f9f9f" />
                </Pressable>}>
                <Menu.Item leadingIcon="account-group" onPress={() => {}} title={perfil.nombreperfil} trailingIcon="chevron-right" />
                <Divider />
                <Menu.Item leadingIcon="cog" onPress={() => {}} title="Opciones" />
                <Menu.Item leadingIcon="logout" onPress={() => {clearSession(); setUsuario(null);}} title="Cerrar Sesión" />
                </Menu>
        </View>
    );
}

const style = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalOption: {
      backgroundColor: 'white',
      padding: 20,
      margin: 10,
      borderRadius: 10,
    },
  });
