const sUrl = "https://testbackend.sanisidrosa.com/api/v1/";
const gUrl = "https://testbackend.sanisidrosa.com/";

export async function getDataAsync(sMethod, user = null, contentType = 'application/json') {
    let headers = {
        'Content-Type': contentType
    };
    if (user != null) {
        headers.Authorization = `Bearer ${user.token}`;
    }
    const response = await fetch(`${sUrl}${sMethod}`, {
        method: 'GET',
        mode: 'cors', 
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    });
    let json = null;
    if (response.ok) {
        json = await response.json();
    }
    return json;
} 

export function postDataApi(method, postData = null, user = null) {
    
}

export async function postDataAsync(sMethod, postData, contentType = 'application/json', fromRoot = true) {
    const response = await fetch(`${gUrl}${sMethod}`, {
        method: 'POST',
        mode: 'cors', 
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': contentType
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(postData) 
    });
    let json = null;
    if (response.ok) {
        json = await response.json();
    }
    return json;

}
   
export function deleteData(method, code, user = null) {

}

